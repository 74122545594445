@font-face {
    font-family: "HKNova";
    src: url("./HKNova-SemiBold.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "HKNova";
    src: url("./HKNova-Bold.ttf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "NanumPen";
    src: url('nanumpenscript-regular-webfont.woff2') format('woff2'),
         url('nanumpenscript-regular-webfont.woff') format('woff'),
         url("./NanumPenScript-Regular.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Quicksand";
    src: url("./Quicksand-Medium.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Quicksand";
    src: url("./Quicksand-Bold.ttf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}
