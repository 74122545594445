@import "./functions";

.small_font {
  font-size: rem(legacy-rem(18));
}

.large_font {
  font-size: rem(legacy-rem(20));

  @include media(">=md") {
    font-size: rem(legacy-rem(30));
  }
}
