@import "~include-media/dist/include-media";
@import "../styles/normalise";
@import "../styles/typography";
@import "../styles/variables";
@import "../styles/colors";
@import "../styles/richtext";

$scrollbar-bg: transparent;
$thumb-bg: #ddd;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbar-bg;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumb-bg ;
    border-radius: 6px;
    border: 3px solid $scrollbar-bg;
  }

  scrollbar-width: thin;
  scrollbar-color: $thumb-bg $scrollbar-bg;
}

html,
body {
  font-family: $bodyFont;
  font-size: $default-body-font-size;
  font-weight: $regular;
  color: $primary;
}

html,
body,
#__next,
#root {
  min-height: 100%;
  max-width: 100vw;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: none;
}

.lg-on {
  position: fixed;
  width: 100%;
}

hr {
  margin: 0;
}


.awssld {
  height: 100vh !important;
}

.awssld__controls {
  visibility: visible !important;
}

.title {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

:root {
  --inframe-carousel-bg-color: #F4F4F4;
  --inframe-carousel-bg-image: 'https://a.storyblok.com/f/105336/3840x2278/c1db7c81ca/footer-cta-bg.jpg';
  --inframe-carousel-offset: 0px;
  --inframe-carousel-arrow-color: #707070;
}

.bigBodyFont {
  font-size: rem(legacy-rem(22));
  line-height: rem(legacy-rem(35));
}

.smallBodyFont {
  font-size: rem(legacy-rem(16));
  line-height: rem(legacy-rem(30));
}

@keyframes wrapper-fade {
  to {
    opacity: 1;
  }
}

.wrapper {
  @include load-animation(wrapper-fade, 0.5s, linear, 0s) {
    opacity: 0;
  }
}
