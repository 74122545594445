@import '~include-media/dist/include-media';
@import '../../styles/colors';
@import '../../styles/functions';

.link {
  font-size: rem(legacy-rem(17));
  line-height: rem(legacy-rem(25));
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: none;
  width: auto;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;

  @include media(">=xl") {
    font-size: rem(legacy-rem(22));
    line-height: rem(legacy-rem(28));
  }



  &:hover &__underline>svg:nth-child(3) {
    path{
      fill: $brand-blue;
    }
    transform: scaleX(1);
    transform: scaleY(1.3);
  }

  &--primary,
  &--primary-with-arrow{
    color: $primary !important;
    transition: color 0.15s ease-in-out;
    position: relative;
    cursor: pointer;
    text-decoration: none !important;

    .link__icon {
      margin-top: -5px;
    }

    &-inverse {
      color: $white !important;
    }

    &:focus {
      outline: none;
    }
  }

  &--secondary,
  &--secondary-with-arrow{
    background-color: $brand-blue;
    padding: rem(legacy-rem(15)) rem(legacy-rem(30)) rem(legacy-rem(15)) rem(legacy-rem(30));
    border-radius: rem(legacy-rem(7));
    color: $white !important;
    align-items: center;
    text-decoration: none !important;

    &:hover {
      color: $white !important;
      background-color: $dark-blue;
    }

    &-inverse {
      background-color: $brand-blue;
      padding: rem(legacy-rem(15)) rem(legacy-rem(30)) rem(legacy-rem(15)) rem(legacy-rem(30));
      border-radius: rem(legacy-rem(7));
      align-items: center;
      color: $black !important;

      &:hover {
        color: $black !important;
        background-color: $dark-blue;
      }

      svg {
        path {
          color: $black;
          fill: $black;
          stroke: $black;
        }
      }
    }
  }

  &--list-style {
    text-decoration: none !important;
    line-height: rem(36);
    max-width: 100%;

    &:hover {
      .link__icon {
          transform: translateX(5px);
      }
    }
  }

  &--default {
    color: $primary;
    border-bottom: 1px $black solid;
    line-height: rem(legacy-rem(22));

    &:hover {
      color: $brand-blue;
      border-bottom: 1px $brand-blue solid;
    }

    &-inverse {
      color: $white !important;
    }
  }

  &__icon {
    margin-left: rem(legacy-rem(10));
    width: rem(legacy-rem(18));
    height: rem(legacy-rem(18));

    path {
      fill: $brand-pink;
      stroke: $brand-pink;
    }

    &--secondary-with-arrow {
      margin-top: 0;
      height: rem(legacy-rem(20));
      width: rem(legacy-rem(20));

      path {
        fill: $white;
        stroke: $white;
      }
    }

    &--list-style {
      margin-right: rem(legacy-rem(20));

      &:last-of-type {

        &:hover {
          transform: translateX(100px);
        }
      }
    }
  }

  &__underline {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    white-space: nowrap;
    width: auto;

    &-svg {
      width: auto;
      margin-top: 5px;
      height: rem(5);

      @include media("<lg") {
        height: rem(3);
      }

      path {
        fill: $brand-pink;
        stroke-dasharray: 1;
        stroke-dashoffset: 1;
      }
    }

    &:hover svg:nth-child(3) {
      path{
        fill: $brand-blue;
      }
      transform: scaleX(1);
      transform: scaleY(1.3);
    }
  }

  .hoverState {

    svg:nth-child(3) {
      path{
        fill: $brand-blue;
      }
      transform: scaleX(1);
      transform: scaleY(1.3);
    }
  }

  &__underlinecover {
    margin-top: rem(-5);
    transform: scaleX(0);
    transform-origin: 0 center;
    transition: 0.5s all;
    width: auto;

    @include media("<lg") {
      margin-top: -3px;
      height: 3px;
    }

    path {
      fill: $brand-blue;
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
    }
  }

  &__text {
    display: block;
    white-space: normal;
  }
}

@include media(">=lg") {
  .centredLink {
    margin-right: auto;
    margin-left: auto;
  }

  .linkLeft {
    margin-left: 0;
  }

  .linkRight {
    margin-right: 0;
  }
}

@keyframes hoverColor {
  100% {
    fill: $brand-blue;
  }
}
