@import "./variables";
@import "./colors";
@import "./functions";

h1,
.h1 {
  font-family: $headerFont;
  font-size: rem(24);
  line-height: rem(36);
  margin: 0 0 rem(26);
  display: block;
  font-weight: $bold;
  width: 100%;

  @include media(">=lg") {
    font-size: rem(legacy-rem(40));
    line-height: rem(legacy-rem(50));
    margin: 0 0 rem(legacy-rem(30));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(50));
    line-height: rem(legacy-rem(70));
    margin: 0 0 rem(legacy-rem(40));
  }
}

h2,
.h2 {
  font-family: $headerFont;
  font-size: rem(22);
  line-height: rem(30);
  margin-bottom: rem(30);
  font-weight: $bold;
  width: 100%;

  @include media(">=lg") {
    font-size: rem(legacy-rem(35));
    line-height: rem(legacy-rem(50));
    margin: 0 0 rem(legacy-rem(30));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(45));
    line-height: rem(legacy-rem(60));
    margin: 0 0 rem(legacy-rem(40));
  }
}

h3,
.h3 {
  font-family: $headerFont;
  font-size: rem(18);
  line-height: rem(30);
  margin: 0 0 rem(26);
  font-weight: $bold;
  width: 100%;

  @include media(">=lg") {
    font-size: rem(legacy-rem(20));
    line-height: rem(legacy-rem(30));
    margin: 0 0 rem(legacy-rem(26));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(24));
    line-height: rem(legacy-rem(36));
    margin: rem(legacy-rem(-8)) 0 rem(legacy-rem(40));
  }
}

h4,
.h4 {
  font-family: $headerFont;
  font-size: rem(17);
  line-height: rem(28);
  margin: 0 0 rem(30);
  font-weight: $bold;
  width: 100%;

  @include media(">=lg") {
    font-size: rem(legacy-rem(18));
    line-height: rem(legacy-rem(26));
    margin: 0 0 rem(legacy-rem(30));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(20));
    line-height: rem(legacy-rem(30));
    margin-top: rem(legacy-rem(6));
  }
}

h5,
.h5 {
  font-size: rem(16);
  line-height: rem(22);
  margin: 0 0 rem(30);
  width: 100%;

  @include media(">=lg") {
    font-size: rem(legacy-rem(17));
    line-height: rem(legacy-rem(30));
    margin: 0 0 rem(legacy-rem(30));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(18));
    line-height: rem(legacy-rem(36));
    margin-top: rem(9)
  }
}

h6,
.h6 {
  color: #4a4a4a !important;
  font-size: rem(16.2);
  line-height: rem(25.2);
  width: 100%;

  @include media(">=xl") {
    margin: 0 0 rem(legacy-rem(30));
  }
}

a {
  color: $primary;
  text-decoration: underline;
}

strong {
  font-weight: $bold;
}

p {
  font-family: $bodyFont;
  font-size: rem(15);
  line-height: rem(22);
  margin-bottom: rem(20);
  display: block;
  width: 100%;

  @include media(">=lg") {
    font-size: rem(legacy-rem(16));
    line-height: rem(legacy-rem(28));
    margin-bottom: rem(legacy-rem(30));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(18));
    line-height: rem(legacy-rem(36));
    margin-bottom: rem(legacy-rem(30));
  }

  strong {
    font-weight: $bold;
  }

  &.small {
    font-size: rem(14);
    line-height: rem(22);
    margin: 0 0 rem(20);
    display: block;

    strong {
      font-weight: $bold;
    }

    @include media(">=lg") {
      font-size: rem(legacy-rem(15));
      line-height: rem(legacy-rem(28));
      margin-bottom: rem(legacy-rem(30));

      strong {
        font-weight: $bold;
      }
    }

    @include media(">=xl") {
      font-size: rem(legacy-rem(16));
      line-height: rem(legacy-rem(30));
    }
  }
}

a:not([class]) {
  font-size: rem(15);
  line-height: rem(22);
  text-decoration: underline;
  transition: all .3s ease-in;

  @include media(">=lg") {
    font-size: rem(legacy-rem(16));
    line-height: rem(legacy-rem(28));
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(18));
    line-height: rem(legacy-rem(36));
  }

  &:hover,
  &:active,
  &:focus {
    color: $brand-pink;
    text-decoration-color: $brand-pink;
  }
}

small {
  font-size: rem(14);
  line-height: rem(22);
  margin: 0 0 rem(20);
  display: block;
  width: 100%;

  strong {
    font-weight: $bold;
  }

  @include media(">=lg") {
    font-size: rem(legacy-rem(15));
    line-height: rem(legacy-rem(28));
    margin-bottom: rem(legacy-rem(30));

    strong {
      font-weight: $bold;
    }
  }

  @include media(">=xl") {
    font-size: rem(legacy-rem(16));
    line-height: rem(legacy-rem(30));
  }
}

section {
  margin: 0;
  padding: 0;
}

ul,
ol {
  padding: 0;
  margin: 0 0 rem(30) 0;
  width: 100%;

  @include media(">=xl") {
    margin: 0 0 rem(legacy-rem(20)) 0;
  }

  li {
    font-size: rem(15);
    line-height: rem(22);

    @include media(">=lg") {
      font-size: rem(legacy-rem(16));
      line-height: rem(legacy-rem(28));
    }

    @include media(">=xl") {
      font-size: rem(legacy-rem(18));
      line-height: rem(legacy-rem(30));
    }
  }
}

ul {
  &.unorderedList {
    width: 100%;

    p {
      margin-bottom: 0;
    }
  }
}

ol {
  &.orderedList {
    width: 100%;

    p {
      margin-bottom: 0;
    }
  }
}

ul:not([class]) {
  margin-left: rem(20);

  ul:not([class]) {
    margin-left: rem(20);
  }

  & > li > ol li {
    margin-top: rem(-13);
  }

  & li > p, & ul {
    margin-bottom: 0;
  }
}

ol::marker {
  color: $secondary-orange;
  font-family: $headerFont;
  font-weight: $bold;
  font-size: rem(16.2);
}

ol:not([class]) {
  margin-left: rem(20);

  & > li > ul li {
    margin-top: rem(-13);
  }

  ul ::marker {
    color: black;
  }

  ol:not([class]) {
    margin-left: rem(20);
  }

  li:not([class]) {
    position: relative;

    & > p {
      @include media('>=xl') {
        line-height: rem(28);
        margin-bottom: rem(legacy-rem(20));
      }
    }
  }
}

hr {
  margin: 0 0 rem(30);
  width: 100%;

  @include media(">=xl") {
    margin: 0 0 rem(legacy-rem(40));
  }
}
