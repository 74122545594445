@use "sass:math";

@import "~include-media/dist/include-media";
@import '../../styles/common';

.grid-row {
  & > div {
    width: 100%;
    
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &:global(.services-class)
  {
    max-width: 100%;
    
    .grid-row {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;

      @media (max-width: map_get($breakpoints, sm)) {
        flex-direction: column;
        flex-basis: auto;
      }
    }

    .grid-item {
      width: 100%;
      margin: 0;
      padding: 0;
      @media (max-width: map_get($breakpoints, sm)) {
        flex-basis: auto;
      }

      &:first-child {
        padding-right: rem(legacy-rem(45));
        @media (max-width: map_get($breakpoints, md)) {
          padding: 0;
        }
      }


      &:only-child {
        padding: 0;
      }
      &:nth-child(2) {
        padding-left: rem(legacy-rem(45));
        @media (max-width: map_get($breakpoints, md)) {
          padding: rem(legacy-rem(30)) 0 0 0;
        }

        .grid-item {
          padding-left: 0;
        }
      }
    }
  }

  &:global(.alternate-accordion-flexible-class)
  {
    max-width: 100%;
    .grid-row {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      @media (max-width: map_get($breakpoints, sm)) {
        flex-direction: column;
        flex-basis: auto;
      }
    }
    .grid-item {
      width: 100%;
      margin: 0;
      padding: 0;
      @media (max-width: map_get($breakpoints, md)) {
        flex-basis: auto;
        &:not(:last-child){
          margin-bottom: rem(legacy-rem(50));
          padding-bottom: rem(legacy-rem(50));
          border-bottom: dashed 2px $secondary-lightgrey2;
        }
      }

      &:first-child {
        padding-right: rem(legacy-rem(45));
        @media (max-width: map_get($breakpoints, md)) {
          padding-right: 0;
        }
      }

      &:last-child {
        padding-left: rem(legacy-rem(45));
        @media (max-width: map_get($breakpoints, md)) {
          padding-left: 0;
        }
      }


      &:only-child {
        padding: 0;
      }
    }
  }

  &-item {
    flex: 1 1 rem(legacy-rem(0)); // This is an IE11 related bug, otherwise I'd not use rem(legacy-rem(0) I'd just use 0
    @media (max-width: map_get($breakpoints, md)) {
      padding: unset;
    }

    @each $breakpoint, $px in $breakpoints {
      @include media(">=#{$breakpoint}") {
        @for $i from 1 through 12 {
          &--#{$breakpoint}-#{$i} {
            max-width: #{math.div($i, 12) * 100%};
            width: #{math.div($i, 12) * 100%};
            flex-basis: #{math.div($i, 12) * 100%};
          }
        }
      }
    }
  }


  &-container {
    margin-left: auto;
    margin-right: auto;
    max-width: #{rem(map_get($container-max-width, sm))};

    @each $breakpoint, $width in $container-max-width {
      @include media(">=#{$breakpoint}") {
        max-width: #{rem($width)};
      }
    }

    &--fluid {
      margin-left: $container-gutter-width * 0.5;
      margin-right: $container-gutter-width * 0.5;
      max-width: 100%;
      width: auto;

      @include media('>=xl') {
        margin: 0 auto;
      }
    }
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  &--without-margin {
    &:not(.grid-item) {
      margin: auto;
      max-width: 100%;
    }
  }

  &--vertical {
    align-items: center;
  }
}
