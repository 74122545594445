@import "~include-media/dist/include-media";
@import '../../styles/variables';
@import "../../styles/colors";
@import "../../styles/functions";

@keyframes fade-in {
  to {
    opacity: 1;
    top: 0;
  }
}

.logo {
  position: relative;
  height: rem(legacy-rem(30));
  min-height: auto;
  margin-top: 10px;

  &--animated {
    @include load-animation(fade-in, 0.5s, ease-in-out) {
      opacity: 0;
      top: rem(20);
    }
  }

  @include media(">=lg") {
    height: rem(legacy-rem(40));
    margin-top: unset;
  }

  @include media("<=md") {
    width: rem(112);
    height: rem(21);
    margin-top: 0;
  }

  &__path {
    fill: $primary;

    &--blue {
      fill: $primary-lightblue;
    }
  }

  &__button {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
  }

  &.inverse {
    .logo {
      &__path {
        fill: $white;

        &--blue {
          fill: $primary-lightblue;
        }
      }
    }
  }
}

.dot {
  &--animated {
    @include load-animation(fade-in, 0.5s, ease-in-out, 1s) {
      opacity: 0;
    }
  }
}
