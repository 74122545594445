@import "~include-media/dist/include-media";
@import '../../../styles/variables';
@import "../../../styles/colors";
@import "../../../styles/functions";

.title,
.nav {
  align-items: center;
  display: flex;

  &__menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    > li {
      a {
        margin: auto rem(legacy-rem(30));
        transition: all 0.15s ease-in-out;
        color: $primary;
        font-size: rem(legacy-rem(20));
        line-height: rem(legacy-rem(25));

        @include media('>=lg') {
          margin: auto rem(legacy-rem(48));
          padding-top: rem(15);
        }

        &:hover {
          color: $primary-lightblue;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }

  &__link {
    color: $primary;
    margin: auto rem(legacy-rem(30));
    text-decoration: none;
    font-size: rem(legacy-rem(20));
    line-height: rem(legacy-rem(25));
    transition: color 0.15s ease-in-out;
    position: relative;
    cursor: pointer;

    &:hover {
      color: $primary-lightblue;
    }

    &.selected {
      &:hover {
        color: $primary;
      }
    }

    @include media('>=xl') {
      margin: auto rem(legacy-rem(45));
    }
  }

  &.dark {
    .nav {
      &__menu {
        > li {
          a {
            color: $white;

            &:hover {
              color: $primary-lightblue;
            }
          }
        }
      }

      &__link {
        color: $white;

        &:hover {
          color: $primary-lightblue;
        }

        &.selected {
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
