@import "~include-media/dist/include-media";
@import '../../styles/common';

.stopScroll {
  overflow: hidden !important;
  height: 100% !important;
}

.mobile-menu {
  &__open {
    position: fixed;
    top: 0;
    right: 0;
    background: transparent;
    width: 100%;
    height: 100%;

    .mobile-menu {
      &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: $primary;
        width: 100%;
        z-index: 51;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;

        @include media('>=lg') {
          display: none;
        }
      }

      &__footer-background {
        background-image: url('../../../public/images/jungle-office.png');
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100vw;
        height: 50vh;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 50;
        background-size: cover;

        @include media (">=sm") {
          height: 100vh;
        }
      }
    }

    @include media('>=lg') {
      display: none;
    }
  }

  &__closed {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 100vh;
    pointer-events: none;

    @include media('>=lg') {
      display: none;
    }

    .mobile-menu {
      &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: $primary;
        z-index: 50;
        width: rem(80);
        height: rem(80);

        @include media('>=lg') {
          display: none;
        }
      }

      &__footer-background {
        width: 100vw;
        height: 50vh;
        position: absolute;
        bottom: 0;
        left: 0;

        @include media (">=sm") {
          height: 100vh;
        }
      }
    }
  }

  &__nav {
    padding-top: 100px;
    margin-bottom: 100px;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 55;
    position: relative;
    overflow: scroll;

    &--menu {
      color: $white;
      list-style: none;
      font-size: rem(legacy-rem(20));
      line-height: rem(legacy-rem(50));
      margin-bottom: rem(30);
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-left: 0;

      &--link {
        color: $white;
        text-decoration: none;
        transition: color 0.15s ease-in-out;
        margin-bottom: rem(legacy-rem(20));
        font-size: rem(20);
        font-weight: 700;
        line-height: rem(40);
        display: flex;
        flex-direction: row;
        cursor: pointer;
        border: none;
        width: fit-content;
        white-space: nowrap;

        &:hover {
          color: $primary-lightblue;
        }

        &--selected {
          color: $primary-lightblue;
        }
      }

      li:last-child {
        .mobile-menu__nav--menu--link {
          margin-bottom: 0;
        }
      }
    }

    &--contact {
      &--text {
        position: relative;
        transform: rotate(10deg);
        margin-left: rem(legacy-rem(-48));
        margin-top: rem(legacy-rem(-4));
        font-family: $toolTipFont;

        span {
          color: rgba($white, 0.6);
          width: rem(legacy-rem(60));
          display: block;
        }

        svg {
          transform: rotate(-8deg);
        }
      }

      &--link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: transparent;
        border: none;
      }
    }
  }

  &_nav {
    &__toggle {
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      z-index: 100;
      user-select: none;
      position: absolute;
      top: 34px;
      right: rem(36);
      pointer-events: all;

      @include media('<=md') {
        top: 13px;
        right: rem(23);
        padding: 10.5px 9px 3px 8px;
      }
    }
  }

  &__logo-wrapper {
    top: rem(20);
    left: rem(25);
    position: absolute;
    z-index: 60;

    svg {
      top: 0;
      left: 0;
    }
  }
}
