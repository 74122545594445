@import "../../../styles/functions";

.icon {
  position: absolute;
  font-size: rem(legacy-rem(18));
  color: #9ED974;
  transform: rotateY(-180deg);
  width: rem(legacy-rem(18));

  &__bg {
    width: rem(legacy-rem(52));
  }
}
