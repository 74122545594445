@import "~include-media/dist/include-media";
@import '../../styles/variables';
@import '../../styles/colors';
@import "../../styles/functions";

.header {
  color: $primary;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  z-index: 100;
  padding: rem(20) rem(25);

  @include media("<lg") {
    margin-right: unset;
    width: 100%;
    max-width: unset;
    margin-left: unset;
  }

  @include media(">=lg") {
    padding: rem(legacy-rem(105)) rem(legacy-rem(75)) rem(legacy-rem(90)) rem(5);
  }
}

.mobile {
  display: block;

  @include media(">=lg") {
    display: none;
  }
}

.desktop {
  display: none;

  @include media(">=lg") {
    display: block;
  }
}
